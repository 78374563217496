<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="justify-center d-flex align-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">FWITTY</h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">Please sign-in to your account</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="login.email"
              :rules="[rules.required, rules.email]"
              outlined
              label="Email"
              placeholder="exaple@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="login.password"
              :rules="[rules.required, rules.counter]"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="flex-wrap d-flex align-center justify-space-between">
              <v-checkbox label="Remember Me" hide-details class="mt-1 me-3"> </v-checkbox>

              <!-- forgot link -->
              <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
            </div>

            <v-btn :loading="loading" @click="submit" block color="primary" class="mt-6"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    return {
      isPasswordVisible,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value && value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      login: {
        email: null,
        password: null,
      },
      loading: false
    }
  },
  methods: {
    submit(){
      if(this.$refs.form.validate()){
        this.loading = true
        this.$api
        .login(this.login)
        .then((response) => {
          if (response && response.id && response.user_type == 1) {
            this.$api.get("init").then((resp) => {
              this.$store.commit("Init/INIT", resp.data);
              this.$router.push({ name: "dashboard" });
              this.$toast.info(response.message)
              this.loading = false
            });
          }
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
